<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProvince
                    style="width:100%;"
                    v-model:value="state.params.provinsi"
                    v-model:region="state.params.region"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterArea
                    style="width:100%;"
                    v-model:value="state.params.area"
                    v-model:region="state.params.region"
                    v-model:provinsi="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterKabupatenKota
                    style="width:100%;"
                    v-model:value="state.params.kabupaten"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:provinsi="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <!-- 1259927: GM, 55414: TSO, 55407: ASM, 55403: SSM -->
                <FilterUsers
                    style="width:100%;"
                    placeholder="Pilih Sales Person"
                    :role_id="[1259927, 55414, 55407, 55403]"
                    v-model:value="state.params.salesman"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <!-- button action menu -->
        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip title="Download excel">
                        <DownloadExcel
                            :url="state.url"
                            :params="state.params"
                            namefile="Laporan-Mapping-Wilayah-Sales-Person"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
import FilterUsers from '@/components/filter/FilterUsers'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import { pickBy } from 'lodash'
import {
    hasRoles,
    ROLE_ADMIN_BK,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterRegional,
        FilterProvince,
        FilterArea,
        FilterKabupatenKota,
        FilterUsers,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'No',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Region',
                    dataIndex: 'region_name',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'provinsi_name',
                },
                {
                    title: 'Area',
                    dataIndex: 'area_name',
                },
                {
                    title: 'Kabupaten',
                    dataIndex: 'kabupaten_name',
                },
                {
                    title: 'SSM',
                    dataIndex: 'ssm_name',
                },
                {
                    title: 'ASM',
                    dataIndex: 'asm_name',
                },
                {
                    title: 'TSO',
                    dataIndex: 'tso_name',
                },
            ],
            url: '/api/report/mapping-wilayah-salesperson',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: {
                q: '',
                region: [],
                provinsi: [],
                area: [],
                kabupaten: [],
                salesman: [],
                page: 1,
                "per-page": 10,
            },
            useram_id: null,
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.url, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    
                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        // handle vue
        onMounted(() => {
            fetchDataList()
        })

        return {
            fetchDataList,
            state,
            handleTableChange,
            errorMessage,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>
